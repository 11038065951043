/*! nouislider - 14.6.3 - 11/19/2020 */

// Orginial styles

.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-target {
    position: relative
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1
}

.noUi-connects {
    overflow: hidden;
    z-index: 0
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat
}

.noUi-connect {
    height: 100%;
    width: 100%
}

.noUi-origin {
    height: 10%;
    width: 10%
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: 0;
    right: auto
}

.noUi-vertical .noUi-origin {
    width: 0
}

.noUi-horizontal .noUi-origin {
    height: 0
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute
}

.noUi-touch-area {
    height: 100%;
    width: 100%
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform .3s;
    transition: transform .3s
}

.noUi-state-drag * {
    cursor: inherit !important
}

.noUi-horizontal {
    height: 18px
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -17px;
    top: -6px
}

.noUi-vertical {
    width: 18px
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    right: -6px;
    top: -17px
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    left: -17px;
    right: auto
}

.noUi-target {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-connects {
    border-radius: 3px
}

.noUi-connect {
    background: #3FB8AF
}

.noUi-draggable {
    cursor: ew-resize
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:after,
.noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px
}

.noUi-handle:after {
    left: 17px
}

.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px
}

.noUi-vertical .noUi-handle:after {
    top: 17px
}

[disabled] .noUi-connect {
    background: #B8B8B8
}

[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
    cursor: not-allowed
}

.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-pips {
    position: absolute;
    color: #999
}

.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px
}

.noUi-marker {
    position: absolute;
    background: #CCC
}

.noUi-marker-sub {
    background: #AAA
}

.noUi-marker-large {
    background: #AAA
}

.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%)
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%)
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px
}

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%)
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%
}

.noUi-horizontal .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px
}

.noUi-vertical .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px
}

//  Custom styles

.noUi-target {
    background: $noui-target-bg;
    border-radius: $noui-target-border-radius;
    border: 0;
    box-shadow: $noui-target-box-shadow;
    margin: 15px 0;
    cursor: pointer;
}

.noUi-horizontal {
    height: $noui-target-thickness;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -10px;
}

.noUi-vertical {
    width: $noui-target-thickness;
}

.noUi-connect {
    background: theme-color("primary");
    box-shadow: none;
}

.noUi-handle {
  &:before, &:after{
    content: none;
  }
  &:focus{
    outline: none;
  }
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
    top: -$noui-target-thickness;
    width: $noui-handle-width;
    height: $noui-handle-width;
    border-radius: 100%;
    box-shadow: none;
    cursor: pointer;
    background-color: $noui-handle-bg;
    border: $noui-handle-border;
    transition: box-shadow .15s, transform .15s;
}


.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
    transform: scale(1.2);
}

.noUi-horizontal .noUi-active,
.noUi-vertical .noUi-active {
    //box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1);
}

.input-slider--cyan .noUi-connect {
    background: $cyan;
}

.input-slider--cyan.noUi-horizontal .noUi-handle,
.input-slider--cyan.noUi-vertical .noUi-handle {
    background-color: $cyan;
}

.input-slider--red .noUi-connect {
    background: $red;
}

.input-slider--red.noUi-horizontal .noUi-handle,
.input-slider--red.noUi-vertical .noUi-handle {
    background-color: $red;
}

.input-slider--green .noUi-connect {
    background: $green;
}

.input-slider--green.noUi-horizontal .noUi-handle,
.input-slider--green.noUi-vertical .noUi-handle {
    background-color: $green;
}

.input-slider--yellow .noUi-connect {
    background: $yellow;
}

.input-slider--yellow.noUi-horizontal .noUi-handle,
.input-slider--yellow.noUi-vertical .noUi-handle {
    background-color: $yellow;
}

.input-slider--pink .noUi-connect {
    background: $pink;
}

.input-slider--pink.noUi-horizontal .noUi-handle,
.input-slider--pink.noUi-vertical .noUi-handle {
    background-color: $pink;
}
/* Disabled state */
[disabled] .noUi-connect,
[disabled].noUi-connect {
    background: $noui-slider-connect-disabled-bg;
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
    cursor: not-allowed;
}

/* Range slider value labels */
.range-slider-value {
    font-size: $font-size-xs;
    font-weight: 500;
    background-color: rgba($dark, .7);
    color: color-yiq($dark);
    border-radius: 10px;
    padding: .4em .8em .3em .85em;
}

.range-slider-wrapper .upper-info {
    font-weight: 400;
    margin-bottom: 5px;
}

.input-slider-value-output {
    background: #333;
    color: #fff;
    padding: 4px 8px;
    position: relative;
    top: 12px;
    font-size: 11px;
    border-radius: 2px;
}

.input-slider-value-output:after {
    bottom: 100%;
    left: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #333;
    border-width: 4px;
    margin-left: -4px;
}

.input-slider-value-output.left:after {
    left: 10px;
    right: auto;
}

.input-slider-value-output.right:after {
    right: 10px;
    left: auto;
}
